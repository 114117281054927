import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteUserById,
  editUserById,
  getUserById,
} from "../../Services/Https/user";
import {
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useMemo, useState } from "react";
import { getCertCategories } from "../../Services/Https/certifications";
import { enqueueSnackbar } from "notistack";
import color from "../../Constants/colors";
import { statusToString } from "../../Services/Functions/statusToString";
import {
  DocumentType,
  ProcessingStatus,
  UserRole,
  ModelLevel,
} from "../../Models/user";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import QuestionListsTable from "./QuestionListTable";
import { Certification } from "../../Models/certification";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { formatDate } from "../../helpers/helpers";
import { statusToStringWebsites } from "../../Services/Functions/statusToStringWebsite";

const UserProfilePage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dailyLimit, setDailyLimit] = useState(0);
  const [maxCompanyLimit, setMaxCompanyLimit] = useState(0);
  const [userRole, setUserRole] = useState(UserRole.USER);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [companies, setCompanies] = useState<any>({});
  const [certCategory, setCertCategory] = useState<Certification[]>([]);
  const [certCategories, setCertCategories] = useState<Certification[]>([]);
  const [modelLevel, setModelLevel] = useState(ModelLevel.BASIC);
  const { data: user, isLoading } = useQuery(["user", userId], () =>
    getUserById(userId ?? "")
  );

  useEffect(() => {
    if (user) {
      setName(user.name ?? "");
      setSurname(user.surname ?? "");
      setEmail(user.email ?? "");
      setDailyLimit(user.daily_limit ?? 0);
      setMaxCompanyLimit(user.companies_limit ?? 0);
      setModelLevel(user.model_level ?? ModelLevel.BASIC);
      setUserRole(user.role ?? UserRole.USER);
      setCertCategory(user.certifications ?? []);
      createHashMapCompany(user.companies);
    }
  }, [user]);

  const formattedDocuments = useMemo(() => {
    return user?.documents.map((document) => ({
      ...document,
      formattedDate: formatDate(document.upload_date),
    }));
  }, [user?.documents]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getCertCategories();
        setCertCategories(categories);
      } catch (err) {
        enqueueSnackbar("Failed to fetch categories", { variant: "error" });
      }
    };

    fetchCategories();
  }, []);

  const createHashMapCompany = (companies: any) => {
    const hashMap: any = {};
    if (!companies) return;
    companies.forEach((company: any) => {
      hashMap[company.id] = company.name;
    });
    setCompanies(hashMap);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const processingStatusToString = (status: number) => {
    switch (status) {
      case ProcessingStatus.WAITING:
        return "Waiting";
      case ProcessingStatus.IN_PROGRESS:
        return "In Progress";
      case ProcessingStatus.PROCESSED:
        return "Processed";
      case ProcessingStatus.ERROR:
        return "Error";
      case ProcessingStatus.TIME_LIMIT_EXCEEDED:
        return "Error: time limit exceeded";
      default:
        return "";
    }
  };

  const documentTypeToString = (type: number) => {
    switch (type) {
      case DocumentType.TXT:
        return "Text";
      case DocumentType.WORD:
        return "Word";
      case DocumentType.PDF:
        return "PDF";
      case DocumentType.POWERPOINT:
        return "Powerpoint";
      case DocumentType.IMAGE:
        return "Image";
      default:
        return "";
    }
  };

  const companyName = (id: number) => {
    return companies[id];
  };

  const handleEditUser = async () => {
    if (name.trim() === "") {
      enqueueSnackbar("Name cannot be empty.", { variant: "warning" });
      return;
    }

    if (surname.trim() === "") {
      enqueueSnackbar("Surname cannot be empty.", { variant: "warning" });
      return;
    }

    if (email.trim() === "") {
      enqueueSnackbar("Email cannot be empty.", { variant: "warning" });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      enqueueSnackbar("Please enter a valid email address.", {
        variant: "warning",
      });
      return;
    }

    try {
      if (certCategory.length === 0) {
        enqueueSnackbar("Please select at least one certification category.", {
          variant: "error",
        });
        return;
      }

      const cert_category_ids = certCategory.map((cert) => cert.id);

      await editUserById(
        userId ?? "",
        name,
        surname,
        email,
        dailyLimit,
        maxCompanyLimit,
        password,
        modelLevel,
        cert_category_ids
      );
      enqueueSnackbar("User edited successfully", { variant: "success" });
      setIsEditable(false);
    } catch (err) {
      enqueueSnackbar("Failed to edit user", { variant: "error" });
    }
  };

  const handleConfirmationDialogOpen = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = (
    confirmed: boolean,
    reason: string
  ) => {
    if (confirmed) {
      handleDelete();
    } else {
      setConfirmationDialogOpen(false);
    }
  };

  const handleDialogClose = (event: any, reason: string) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      setConfirmationDialogOpen(false);
      return;
    }
    handleConfirmationDialogClose(false, reason);
  };

  const handleDelete = async () => {
    try {
      await deleteUserById(userId ?? "");
      enqueueSnackbar("User deleted successfully", { variant: "success" });
      navigate("/users");
    } catch (err) {
      enqueueSnackbar("Failed to delete user", { variant: "error" });
    }
  };

  const handleCertCategoryChange = (category: Certification) => {
    setCertCategory((prevCategories) => {
      if (prevCategories.some((c) => c.id === category.id)) {
        return prevCategories.filter((c) => c.id !== category.id);
      } else {
        return [...prevCategories, category];
      }
    });
  };

  return (
    <Container maxWidth="md">
      <Grid item display="flex" justifyContent="space-between">
        <Grid item mb={1}>
          <Typography variant="h3" sx={{ color: color.white }}>
            User Profile
          </Typography>
        </Grid>
        <Grid item>
          <Dialog
            open={confirmationDialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              <Typography sx={{ color: color.white }}>
                Remove user confirmation
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="caption" sx={{ color: color.white }}>
                  Are you sure you want to remove this user?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  marginBottom: "10px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
                onClick={() => {
                  handleConfirmationDialogClose(false, "");
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  marginBottom: "10px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
                onClick={() => {
                  handleConfirmationDialogClose(true, "");
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <Tooltip title="Edit users info">
            <IconButton
              onClick={() => setIsEditable(!isEditable)}
              aria-label="edit"
              disabled={isEditable}
              sx={{
                color: color.btn_dark,
                "&.Mui-disabled": {
                  color: color.textDisabled,
                },
              }}
            >
              <EditIcon
                sx={{
                  color: "inherit",
                }}
              />
            </IconButton>
          </Tooltip>
          {isEditable && (
            <>
              <Tooltip title="Cancel edit">
                <IconButton
                  onClick={() => setIsEditable(!isEditable)}
                  aria-label="cancel"
                >
                  <CancelIcon sx={{ color: color.btn_dark }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save changes">
                <IconButton onClick={() => handleEditUser()} aria-label="edit">
                  <SaveIcon sx={{ color: color.btn_dark }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title="Delete users info">
            <IconButton
              onClick={handleConfirmationDialogOpen}
              disabled={confirmationDialogOpen}
              aria-label="delete"
            >
              <DeleteIcon sx={{ color: color.btn_dark }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            name="name"
            value={name}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={!isEditable}
            onChange={(e) => setName(e.target.value)}
            InputLabelProps={{ style: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Surname"
            name="surname"
            value={surname}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={!isEditable}
            onChange={(e) => setSurname(e.target.value)}
            InputLabelProps={{ style: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            name="email"
            value={email}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={!isEditable}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ style: { color: "white" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            name="password"
            fullWidth
            variant="outlined"
            margin="normal"
            type="password"
            disabled={!isEditable}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{ style: { color: "white" } }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Daily Limit"
            name="daily_limit"
            value={dailyLimit}
            fullWidth
            type="number"
            variant="outlined"
            margin="normal"
            disabled={!isEditable}
            onChange={(e) => {
              setDailyLimit(parseInt(e.target.value));
            }}
            InputLabelProps={{ style: { color: "white" } }}
          />
          <Typography variant="caption" gutterBottom sx={{ color: "gray" }}>
            Evaluations: {user?.evaluations}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Max Company Limit"
            name="max_company_limit"
            value={maxCompanyLimit}
            fullWidth
            variant="outlined"
            margin="normal"
            type="number"
            disabled={!isEditable}
            onChange={(e) => setMaxCompanyLimit(parseInt(e.target.value))}
            InputLabelProps={{ style: { color: "white" } }}
          />
          <Typography variant="caption" gutterBottom sx={{ color: "gray" }}>
            Companies : {user?.companiesCount}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={!isEditable}
          >
            <InputLabel sx={{ color: "white" }}>AI Model Level</InputLabel>
            <Select
              label="AI Model Level"
              name="model_level"
              value={modelLevel}
              onChange={(e) => setModelLevel(e.target.value as ModelLevel)}
              fullWidth
              sx={{ color: "white" }}
            >
              <MenuItem value={ModelLevel.BASIC}>Basic</MenuItem>
              <MenuItem value={ModelLevel.ENTRY}>Entry</MenuItem>
              <MenuItem value={ModelLevel.FULL}>Full</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: "white" }}>User Categories</Typography>
          <FormControl
            component="fieldset"
            fullWidth
            margin="normal"
            disabled={!isEditable}
          >
            <FormGroup>
              {certCategories.map((category) => (
                <FormControlLabel
                  key={category.id}
                  control={
                    <Checkbox
                      checked={certCategory.some((c) => c.id === category.id)}
                      onChange={() => handleCertCategoryChange(category)}
                      sx={{ color: "white" }}
                    />
                  }
                  label={
                    <Typography sx={{ color: "white" }}>
                      {category.name}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={!isEditable}
          >
            <InputLabel sx={{ color: "white" }}>User Role</InputLabel>
            <Select
              label="User Role"
              name="user_role"
              value={userRole}
              onChange={(e) =>
                setUserRole(parseInt(String(e.target.value), UserRole.USER))
              }
              fullWidth
              sx={{ color: "white" }}
            >
              <MenuItem value={UserRole.USER}>User</MenuItem>
              <MenuItem value={UserRole.EVALUATOR}>Evaluator</MenuItem>
              <MenuItem value={UserRole.FORM}>Form</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* ---------------------------------- */}

      <Grid container direction="column" alignItems="left">
        <Grid item mt={5}>
          <Typography variant="h4" sx={{ color: color.white }}>
            User Companies
          </Typography>
        </Grid>
        <Grid item mt={2}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: color.btn_dark, fontWeight: "bolder" }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Grade
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.companies && user.companies.length > 0 ? (
                  user?.companies.map((company) => (
                    <TableRow
                      key={company.id}
                      sx={{
                        "&:hover": { backgroundColor: color.primary_dark },
                      }}
                    >
                      <TableCell>{company.id}</TableCell>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>{statusToString(company.status)}</TableCell>
                      <TableCell>
                        {company.grade ? company.grade : ""}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="left">
                      No Companies available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="left">
        <Grid item mt={5}>
          <Typography variant="h4" sx={{ color: color.white }}>
            User Documents
          </Typography>
        </Grid>
        <Grid item mt={2}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ color: color.primary }}>
                  <TableCell
                    sx={{ color: color.btn_dark, fontWeight: "bolder" }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Company Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Document Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Upload Date
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.documents && user.documents.length > 0 ? (
                  formattedDocuments?.map((document) => (
                    <TableRow
                      key={document.id}
                      sx={{
                        "&:hover": { backgroundColor: color.primary_dark },
                      }}
                    >
                      <TableCell>{document.id}</TableCell>
                      <TableCell>{companyName(document.company_id)}</TableCell>
                      <TableCell>{document.name}</TableCell>
                      <TableCell>{document.formattedDate}</TableCell>
                      <TableCell>
                        {processingStatusToString(document.status)}
                      </TableCell>
                      <TableCell>
                        {documentTypeToString(document.type)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="left">
                      No Documents available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="left">
        <Grid item mt={5}>
          <Typography variant="h4" sx={{ color: color.white }}>
            User Websites
          </Typography>
        </Grid>
        <Grid item mt={2}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: color.btn_dark, fontWeight: "bolder" }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Company Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    URL
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.websites && user?.websites.length ? (
                  user.websites.map((website) => (
                    <TableRow
                      key={website.id}
                      sx={{
                        "&:hover": { backgroundColor: color.primary_dark },
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(website.url)}
                    >
                      <TableCell>{website.id}</TableCell>
                      <TableCell>{companyName(website.company_id)}</TableCell>
                      <TableCell>{website.url}</TableCell>
                      <TableCell>
                        {statusToStringWebsites(website.status)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="left">
                      No Websites available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid>{user && <QuestionListsTable userId={user.id} />}</Grid>
    </Container>
  );
};
export default UserProfilePage;
