import React from "react";
import Evaluation from "../Evaluation/Evaluation";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import { CompanyInfo } from "../../Models/company";
import { useReviewQuestions } from "../../Hooks/useReviewQuestions";
import { Button, Grid } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { updateAboutCompany } from "../../Services/Https/company";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import { EvaluationResponse } from "../../Models/evaluation";

interface CompanyStatusContentProps {
  company: CompanyInfo | undefined;
  selectedCompanyId: number | "";
  questionListId: string;
  certificationId: string;
}

const CompanyStatusContent: React.FC<CompanyStatusContentProps> = ({
  company,
  selectedCompanyId,
  questionListId,
  certificationId,
}) => {
  const {
    answers,
    handleAnswerChange,
    handleFileChange,
    questionList,
    handleSubmitUpdateAboutCompany,
  } = useReviewQuestions(
    parseInt(questionListId),
    parseInt(certificationId),
    selectedCompanyId.toString(),
    ""
  );

  const navigate = useNavigate();

  const handleSaveAnswers = async () => {
    try {
      await updateAboutCompany(
        selectedCompanyId.toString(),
        JSON.stringify(answers)
      );
      enqueueSnackbar("Company Updated Successfully", {
        variant: "success",
      });
      navigate("/company");
    } catch {
      enqueueSnackbar("Failed to Update Company", { variant: "error" });
    }
  };

  const evaluationResponse = company?.evaluations.find(
    (evaluation) => evaluation.question_list_id === parseInt(questionListId)
  );

  const parsedEvaluationResponse = evaluationResponse
    ? JSON.parse(evaluationResponse.evaluation as string)
    : null;

  if (parsedEvaluationResponse) {
    return (
      <>
        <Evaluation
          evaluationResponse={parsedEvaluationResponse as EvaluationResponse}
          questionList={questionList}
          answers={answers}
          handleAnswerChange={handleAnswerChange}
          handleFileChange={handleFileChange}
          handleSubmitUpdateAboutCompany={handleSubmitUpdateAboutCompany}
          resetAnswers={false}
          isQuestionFieldDisabled={
            company?.status !== CompanyStatus.NEEDS_MORE_INFO
          }
          onAnswerChange={() => {}}
        />
        {evaluationResponse?.more_info && (
          <Grid
            mt={5}
            sx={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              position: "sticky",
              bottom: 0,
              backgroundColor: color.secondaryDark,
              padding: "1rem",
              zIndex: 1000,
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveAnswers}
              sx={{
                backgroundColor: color.btn_dark,
                color: "white",
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
            >
              Submit answers
            </Button>
          </Grid>
        )}
      </>
    );
  }

  return null;
};

export default CompanyStatusContent;
