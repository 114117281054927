import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";
import { createUser } from "../../Services/Https/user";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../Models/user";
import color from "../../Constants/colors";
import { ModelLevel } from "../../Models/user";
import { Certification } from "../../Models/certification";
import { getCertCategories } from "../../Services/Https/certifications";
import { handleAxiosError } from "../../Services/Https/errorHandler";

const CreateUserPage = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    surname: "",
    role: UserRole.USER,
    daily_limit: 1,
    max_company_limit: 1,
    model_level: ModelLevel.BASIC,
    cert_categories: [] as Certification[],
  });
  const [certCategories, setCertCategories] = useState<Certification[]>([]);
  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    const fetchCertCategories = async () => {
      try {
        const categories = await getCertCategories();
        setCertCategories(categories);
      } catch (error) {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(
          `Error fetching certification categories: ${errorMessage}`,
          { variant: "error" }
        );
      }
    };
    fetchCertCategories();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleCertCategoriesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: Certification
  ) => {
    const isChecked = event.target.checked;

    setUser((prevUser) => {
      const updatedCategories = isChecked
        ? [...prevUser.cert_categories, category]
        : prevUser.cert_categories.filter((c) => c.id !== category.id);

      return {
        ...prevUser,
        cert_categories: updatedCategories,
      };
    });
  };

  const handleSubmit = async () => {
    if (!emailRegex.test(user.email)) {
      enqueueSnackbar("Please enter a valid email address.", {
        variant: "warning",
      });
      return;
    }

    if (user.name.length < 2) {
      enqueueSnackbar("Name must be at least 2 characters long.", {
        variant: "warning",
      });
      return;
    }

    if (user.surname.length < 2) {
      enqueueSnackbar("Surname must be at least 2 characters long.", {
        variant: "warning",
      });
      return;
    }

    if (user.password.length < 7) {
      enqueueSnackbar("Password must be at least 7 characters long.", {
        variant: "warning",
      });
      return;
    }

    const cert_category_ids = user.cert_categories.map((cert) => cert.id);
    try {
      await createUser(
        user.name,
        user.surname,
        user.password,
        user.email,
        user.daily_limit,
        user.max_company_limit,
        user.role,
        user.model_level,
        cert_category_ids // Send only the IDs
      );
      enqueueSnackbar("User created successfully", { variant: "success" });
      navigate("/users");
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error creating user: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Grid item>
        <Typography variant="h3" sx={{ color: color.white }}>
          Create User
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            name="name"
            value={user.name}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Surname"
            name="surname"
            value={user.surname}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            name="email"
            value={user.email}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: { color: color.white },
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            name="password"
            value={user.password}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            type="password"
            InputLabelProps={{
              sx: { color: color.white },
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Daily Limit"
            name="daily_limit"
            value={user.daily_limit}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: { color: color.white },
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Max Company Limit"
            name="max_company_limit"
            value={user.max_company_limit}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: { color: color.white },
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: color.btn_dark,
                },
              }}
            >
              <Typography variant="body1" sx={{ color: color.white }}>
                Role
              </Typography>
            </InputLabel>
            <Select
              label="Role"
              name="role"
              value={user.role}
              onChange={handleChange}
              fullWidth
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: color.primary_dark,
                    opacity: 0.95,
                  },
                },
              }}
            >
              <MenuItem value={UserRole.USER}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    color: color.white,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  User
                </Typography>
              </MenuItem>
              <MenuItem value={UserRole.EVALUATOR}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    color: color.white,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  Evaluator
                </Typography>
              </MenuItem>
              <MenuItem value={UserRole.FORM}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    color: color.white,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  Form
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: color.btn_dark,
                },
              }}
            >
              <Typography variant="body1" sx={{ color: color.white }}>
                AI Model Level
              </Typography>
            </InputLabel>
            <Select
              label="AI Model Level"
              name="model_level"
              value={user.model_level}
              onChange={handleChange}
              fullWidth
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: color.primary_dark,
                    opacity: 0.95,
                  },
                },
              }}
            >
              <MenuItem value={ModelLevel.BASIC}>
                <Typography
                  sx={{
                    color: color.white,
                  }}
                >
                  Basic
                </Typography>
              </MenuItem>
              <MenuItem value={ModelLevel.ENTRY}>
                <Typography
                  sx={{
                    color: color.white,
                  }}
                >
                  Entry
                </Typography>
              </MenuItem>
              <MenuItem value={ModelLevel.FULL}>
                <Typography
                  sx={{
                    color: color.white,
                  }}
                >
                  Full
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            sx={{
              color: color.white,
              borderBottom: "2px solid white",
              lineHeight: "32px",
            }}
          >
            Choose Certification Categories
          </Typography>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormGroup>
              {certCategories.map((category) => (
                <FormControlLabel
                  key={category.id}
                  control={
                    <Checkbox
                      checked={user.cert_categories.some(
                        (c) => c.id === category.id
                      )}
                      onChange={(event) =>
                        handleCertCategoriesChange(event, category)
                      }
                      sx={{ color: color.white }}
                    />
                  }
                  label={
                    <Typography sx={{ color: color.white }}>
                      {category.name}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Tooltip
            title={"Please fill out all fields"}
            disableHoverListener={
              !(
                !user.name ||
                !user.surname ||
                !user.daily_limit ||
                !user.email ||
                !user.max_company_limit ||
                !user.password
              )
            }
          >
            <span>
              <Button
                onClick={handleSubmit}
                disabled={
                  !user.name ||
                  !user.surname ||
                  !user.daily_limit ||
                  !user.email ||
                  !user.max_company_limit ||
                  !user.password
                }
                variant="contained"
                fullWidth
                sx={{
                  marginBottom: 10,
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
              >
                Create
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateUserPage;
