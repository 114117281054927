import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import colors from "../../Constants/colors";

const KnowledgeBase = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ padding: 4, backgroundColor: "transparent", borderRadius: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          sx={{ color: colors.white }}
        >
          Create Knowledge Base
        </Typography>
        <Box
          component="form"
          //  onSubmit={}
          noValidate
          sx={{ mt: 4 }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                name="name"
                // value={}
                // onChange={}
                required
                InputLabelProps={{ style: { color: colors.white } }}
                InputProps={{
                  sx: {
                    color: colors.white,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                name="description"
                // value={}
                // onChange={}
                required
                multiline
                rows={4}
                InputLabelProps={{ style: { color: colors.white } }}
                InputProps={{
                  sx: {
                    color: colors.white,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <Box
                onClick={() =>
                  document.getElementById("hidden-file-input")?.click()
                }
                sx={{
                  minHeight: "200px",
                  border: "2px dashed grey",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <Box sx={{ flex: 1, overflowY: "auto" }}></Box>

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ minHeight: "100%", width: { xs: "85%", md: "100%" } }}
                >
                  <Typography variant="body1" sx={{ color: colors.white }}>
                    Drag and drop some files here or click to upload
                  </Typography>
                  <Tooltip title=".doc, .docx, .pdf, .txt, pptx">
                    <Typography
                      variant="body2"
                      sx={{
                        color: colors.secondaryDark,
                        fontStyle: "italic",
                        opacity: 0.6,
                        marginTop: 2,
                      }}
                    >
                      Allowed types: .docx, .pdf, .txt, .pptx,
                    </Typography>
                  </Tooltip>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box mt={2} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: colors.btn_dark,
                  color: colors.white,
                  "&:hover": {
                    backgroundColor: colors.primary_dark,
                    color: colors.btn_dark,
                  },
                  padding: 2,
                  marginTop: "50px",
                }}
              >
                <Typography variant="body1">Submit</Typography>
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default KnowledgeBase;
