import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Grid,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { useCertification } from "../../Hooks/useCertifications";
import { useQuestionLists } from "../../Hooks/useQuestions";
import theme from "../../Constants/theme";
import { QuestionListDetailsResponse } from "../../Models/question";
import useQuestionListDetails from "../../Hooks/useQuestions";
import color from "../../Constants/colors";
import { UserRole } from "../../Models/user";
import PolicyIcon from "@mui/icons-material/Policy";
import { useSnackbar } from "notistack";

interface CategoryAndQuestionFormProps {
  certificationId: string;
  setCertificationId: React.Dispatch<React.SetStateAction<string>>;
  questionListId: string;
  setQuestionListId: React.Dispatch<React.SetStateAction<string>>;
  currentUserRole: UserRole | undefined;
  userId: number;
  isEvaluateLoading: boolean;
  selectedModelId: number | "";
}

const CategoryAndQuestionForm: React.FC<CategoryAndQuestionFormProps> = ({
  certificationId,
  setCertificationId,
  questionListId,
  setQuestionListId,
  currentUserRole,
  isEvaluateLoading,
  selectedModelId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, setQuestionListDetails] =
    useState<QuestionListDetailsResponse | null>(null);
  const [localIsLoading] = useState(false);
  const [localError] = useState<string | null>(null);

  const {
    data: certifications,
    isLoading: isCertLoading,
    isError: isCertError,
    error: certError,
  } = useCertification(currentUserRole);

  const {
    data: questionLists,
    isLoading: isQLoading,
    isError: isQLError,
    error: qListError,
  } = useQuestionLists();

  const {
    data: fetchedQuestionListDetails,
    isError: queryIsError,
    error: queryError,
  } = useQuestionListDetails(questionListId);

  useEffect(() => {
    if (fetchedQuestionListDetails) {
      setQuestionListDetails(fetchedQuestionListDetails);
    }
  }, [fetchedQuestionListDetails]);

  const isAnyError = isCertError || isQLError || localError || queryIsError;
  const isLoadingError = isCertLoading || isQLoading || localIsLoading;

  if (isLoadingError) return <CircularProgress />;
  if (isAnyError) {
    const errorMessage =
      localError ||
      queryError?.message ||
      certError?.message ||
      qListError?.message ||
      "Error loading data. Your session might have expired.";
    enqueueSnackbar(errorMessage, { variant: "error" });
    return <Typography color="error">{errorMessage}</Typography>;
  }

  const handleCertificationChange = (event: SelectChangeEvent) => {
    setCertificationId(event.target.value);
  };

  const handleQuestionListChange = (event: SelectChangeEvent) => {
    setQuestionListId(event.target.value);
  };

  const filteredQuestionLists =
    questionLists?.question_lists.filter(
      (item) =>
        item.category.id.toString() === certificationId && !item.archived
    ) || [];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel
            id="certification-select-label"
            sx={{
              "&.Mui-focused": {
                color: color.btn_dark,
              },
            }}
          >
            <Typography variant="body1" sx={{ color: color.white }}>
              Select Evaluation Category
            </Typography>
          </InputLabel>
          <Select
            labelId="certification-select-label"
            value={certificationId}
            onChange={handleCertificationChange}
            label="Select Certification Category"
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: color.primary_dark,
                  opacity: 0.95,
                },
              },
            }}
            disabled={isEvaluateLoading || selectedModelId === ""}
          >
            {certifications?.map((cert) => (
              <MenuItem
                style={{ color: "white" }}
                key={cert.id}
                value={cert.id.toString()}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    {cert.name}
                  </Typography>
                  {cert.digital_performance && (
                    <PolicyIcon
                      style={{ color: color.btn_dark, marginLeft: 8 }}
                    />
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {(currentUserRole === UserRole.ADMIN ||
        currentUserRole === UserRole.EVALUATOR) && (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel
              id="question-list-select-label"
              sx={{
                "&.Mui-focused": {
                  color: color.btn_dark,
                },
              }}
            >
              <Typography variant="body1" sx={{ color: color.white }}>
                Select Question List
              </Typography>
            </InputLabel>
            <Select
              labelId="question-list-select-label"
              value={questionListId}
              onChange={handleQuestionListChange}
              label="Select Question List"
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: color.primary_dark,
                    opacity: 0.95,
                  },
                },
              }}
              disabled={isEvaluateLoading || selectedModelId === ""}
            >
              {filteredQuestionLists.map((list) => (
                <MenuItem
                  style={{ color: "white" }}
                  key={list.id}
                  value={list.id.toString()}
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    {list.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default CategoryAndQuestionForm;
