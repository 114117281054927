import { QuestionListDetailsResponse } from "./question";
import { Certification } from "./certification";
interface UserCompany {
  grade: number | null;
  id: number;
  name: string;
  status: number;
}

export enum UserRole {
  USER = 0,
  ADMIN = 1,
  EVALUATOR = 2,
  FORM = 3,
}

export enum ModelLevel {
  BASIC = 0,
  ENTRY = 1,
  FULL = 2,
}

export enum ProcessingStatus {
  WAITING = 0,
  IN_PROGRESS = 1,
  PROCESSED = 2,
  ERROR = 3,
  TIME_LIMIT_EXCEEDED = 4,
}

export enum DocumentType {
  TXT = 0,
  WORD = 1,
  PDF = 2,
  POWERPOINT = 3,
  IMAGE = 4,
}

export interface User {
  companies: UserCompany[];
  email: string;
  id: number;
  name: string;
  surname: string;
  role: number;
  daily_limit: number;
  companies_limit: number;
  companiesCount: number;
  evaluations: number;
  documents: UserDocument[];
  websites: UserWebsite[];
  question_lists: QuestionListDetailsResponse[];
  model_level: ModelLevel;
  certifications: Certification[];
}

export interface UserResponse {
  users: User[];
  page: number;
  page_size: number;
  total: number;
}

export interface UserDocument {
  company_id: number;
  id: number;
  status: number;
  type: number;
  name: string;
  upload_date: string;
}

export interface UserWebsite {
  id: number;
  url: string;
  status: number;
  company_id: number;
}
